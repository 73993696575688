import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

import "../scss/archive.scss"

const TagPage = () => {
  // Static query

  const tags = useStaticQuery(graphql`
    query TagsQuery {
      allMarkdownRemark {
        group(field: frontmatter___tags) {
          tag: fieldValue
          totalCount
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Work" />
      <h1 className="page_title">Tags</h1>
      <div className="work_filter">
        <ul>
          {console.log(tags.allMarkdownRemark.group)}
          {tags.allMarkdownRemark.group.map(tag => (
            <li key={tag.tag}>
              {tag.tag} {tag.totalCount}
            </li>
          ))}
        </ul>
      </div>
      <div className="archive"></div>
    </Layout>
  )
}

export default TagPage
